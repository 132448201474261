import React from 'react'

import { Widget } from '../..'
import AppContext from '../../../context/App/Context'

class CampaignPickerConfigurationWidget extends React.Component {
  context = null

  state = {
    loading: false,
    facebook: ``,
    instagram: `loremols
kathvd25
simkestaes
charlottegils
basilandcoffee
anneliespauwels
saarwgmns
_anneleenc_
charlottevannuffelen
lienmangelschots
emilyvhasselt
jill2204
ruthtessens
elienanthonissen
biesmeyers
laureboven
biekepeeterss
karoliendgsn
charlottedetemmerman
kaat.dillen
lorekeppers
grieties
claudiademaeght
stefaniedns
antonialandrieu
trijnvervoort
zitavennekens
kelly_rekm
bregtjehuysmans
fienvanrompay
ann1ckm
stephaniedierckx90
dimiser
yannick_v_o
kathleenmaes
sas.kia.n
clairerobeyns
winifloreal
verluytenlieze
vdb.jes
karodauwen
marthegilis
femkederoover
lienwellens
leoniegoos
julielaermans
marianneverachtert
chanahiserman
nele_boonen
hannemassonet
evelienpeeters_
hannelieten
nathalie_wouters2
undercovereend
bo_marien
miekesterckx
lienappels
evelinejanssenswillen
isabellamendosa
inge.mertens.77
lisa_lauuu
feehannes
dams_cindy
hanne_dem
anneliesgeerts
lietenfemke
tinnedens
carmenlaeremans
karolambregts
stiengijs
jo.meulemans
laura_vndrvoort
lieveverberne
lisanietvelt
bieterweduwe
__karinslegers
lieke_wtrs
hilde.verheyen
dorien_ooms
evyscherrens
veerlejacobs
kellysihna
lmmlouise
v_h_jelle
mar_hnns
vandesandemarthe
ninavangerven1
charlottegeens
vanhooflaura
annelorereynders
miekeverguts
kirstyjanssens
alinastruyf
katoheyns
celinealenteyns
dominiquelahou
elsvanvossole
michielsjanne
femkevanginkel
hannevansoom
grietsmeyers
silkevangenechten
truivanhemelen
laurebelmans
jillruttenn
noorhuysmans
aerts_lisa
ceulemanssarah
peetersalice
brittpauwels
mariemacken_
janssens_jana
genevievesweep
geukensa
saraboven
ellis.goolaerts
margaretha_loucatos
katrinetje
vangompelsiel
tjulessss
ellenlvns
vandonincka
katrienruts
sharikiekens
anke_w_
dorien_brosens
jolienmertens1
s.j.a.n.saar
livroseandfriends
marthevermeulen
jantejonkers
somethingelz
nnstorm
karolien_landsheer
karoliengilis
liesds1
_stephanievos_
truisyen
liesevenn
karenmateusen
lienvermeylen
katrienvanmuijsen
evaverbraecken
bea_sam
miabackx
julie.dekock
sofiavandeweyer
annelommelen
lauriendams
evypeetermans
sarailiekens
baeckinge
griet_hendrickx_
ellenverbeeck
toon_vh
cle.stephanie
lionqueenann
silkeclaessen
saartjemuller
femvandebroek
valeriegoossens
floor_vandecruys
rietbockx
valeriepaulussen
loredewilde
carolineoeyen
ann_hermans
jillpreudhomme
femke_aerts
roosje.goos
joliebaeke
nanousaelen
___habiba_christina___
katrienvanmiert
jolien_slegers
frederiquemorren
sarahgyesbreghs
ansvansant
janamelis
ingriddeboel
annelies.s.j
nelljanssens
marleen_ooms
lynnfransen1
charlottevandervelde_
elisabellefroid
lieselot.janssens
elisadenys
bel_amara
kimhagenaars
_lisabens
ines.ooms
karenkuppens
charlottelievens
dorien_dejaegher
stef_cilissen
brittclaesens
elkeboerboom
carocoenen86
k.lrmns
seesje
vanbaelemma
lindethee
renee.vandecruys
meghan.vanasten
ingepeeters_
neleheylen1
postfrommars
lieselotreynders
suusdc
nickybuyens
kathleenvansant
tinnelauvrys
nelesomers1
jill.van.de.perre
elisebeyens
stephanie_puts
annevandecraen
jetsevanbouwel
vickyluyten4
fi.minimal
heynslisa
liesbeth.vandenbergh
lieselottebaeke
ann.dho2
janavoets
sannebee
jannedams
bertillevanroosbroeck
sielkedaems
floormathieu
brigittecuyvers
lisavanderstraeten
luytenclaes
annebergmans89
heyns.christel
an.teunkens
inesappels
lisavannuffelen
renderseva
jessicapieroni
holemanskiki
jannestroo
charlottevanouytsel
katriengeenen
loreboets
vincentmelis
xeniawillems
lindseyvleugels
stienbinnemans
juliebinnemans
vervoortotta
verachtertcharlotte
kaayaeyckmans
valeriasereda_
laura.bogaerts
julieborghs
emilie.wuyts
debbie_haesen
extravierge
zoe.vandendorpe
noa.trost
loreschodts
hannev88
grietleemans
nikigoesfreaky
lienborghs
grietvertessen
kim_vertessen
ann_wls
femkesnoeck
sietsegebruers
lisa.schaevers
bogaertskaren
liesbeth_80
evasteenacker
claudiavandael
anneliesleynen
floriannevets
hilde.willems
vwhanne
tinnebaeyens
kaatvandenbergh
mertens.britt
vancauterm
liesbethhaesaert
celienvanbaelen
jasmienvanbroeckhoven
juliewybou
la.vie.de_melanie
ineswolfs
juliehnsls
jillvleugels
nellepeeters
michellebens
kimderuysscher
tessaerpels
laurien_frison
jannethys
woutersyoni
c.kempen
annedebal
klaartjeoptroodt
emmadurnez
elienpeeters83
jo_beerten
marie_marien
elienceelen
catovandamme
lotte_daniels
romi_aerts
margotdierckx
verbinnenlien
lommelenilse
karenvankerckhoven_
ruth_vrhrt
elinenevelsteen
lodewijksmieke
fran.vangheel
siel____
ikke_ennah
marlies.vanherck
liesadriaenssen
shanaschvnls
liselottemeus
loredana.loiacono
annelieslembreghts
kirstenvanspringel
mariska6688
gudrunvanherck
marjan_moreels
missesdorien
ann.marie_vb
do.mi_penn
lien_daems
laura.engelen
leenjanssn
carla_geel
vangorp.daniela
bentevandingenen
belmansester
hannenelissen11
lisavandingenen
lenieooms
martevermeulen
joachimveraghtert
lieslcools
nick_vw_
liesvandecruys
lisa.vrdnck
lottevos1
nelevanturnhout
elisabethvandijck
tin.daems
vermeulenfloremie
kaat1978
levantaci
gilissenkaat
nickylenaerts
jenthemeeus
gracelalaland
ameliewckx
noortjebruyninckx
stefaniepersoons
charlottecraps
gieseliekens
annelies.vanhoof.10
eline_bgrts
laurienoyen
nelebeerten
fleur_verstappen
tinne_vn
brittbelis
janssenjoke
kempenannick
katriendier
iris.vandingenen
astrid.declerck
sofiemermans
janahemelaers
nelevanwesemael
miek_dom
katrien.achten
jetmeynen
rainisijmons
elsheylen
lisawuyts
nathalie.sels
lisemut
lisehendrickx
verwimps
marthevanautryve
nienkerenderss
lotjevandoninck
xlorebeckers
sofiebuyens_
bianca__wouters
bm_cooking
karenbartholomeus
juliewouter
sofiemennes
gittemeyvis
vickyverstrepen
nele_van_de_reyd
stevanheu
annik1vk
hildewalraet
charlotpeeters
joniegraphicdesign
toongerinckx
doriengeudens
victorvanlishout
chrisvandoninck
tinadebeckerwimtina
emily_temmerman
leenvancraybex
glynisvc
lauwrien
joliengoormans
samooms
anneke1012
sarah_boschmans
celien_dercon
loeswynants
bopenasse
heidigeerts
stephaniemolenberghs
stefaniemermans
tessalauw
wijzereizen
rutheke05
inezgeertss
shanavaneysendeyk
griet494
jolinegeunes
valerie_gns
emmavangenechten
ambertaels
tvannaelten
stephanievalckx
debby_faes
timmermans.sophie
eliezvandeweyer
cathovangompel
marijke_verbeeck
valeriegns
loesbijnens
steursels
verlindenceline
kimmertens8
mariekedebelder
jytteooms
magdavanhoof
nele.de.troy
stickensamber
vennekensyasmine
brittluyckx
annelies.jaunelles
hanne.starckx
karlijnthys
ansjeverrezen
joke_vanherck
charlotte.vangenechten
vervoort.annelies
kristelmeeus
wendybosbes
katrienserroyen
___trienka11___
marienhanne
kerckhofslaura
baetenjorien
sofie.degroof
aline.heeren
kthln_bckx
evelien_ae
linkavd
vercaignepeggy
dorienteunkens
sophie.boeckx
thoelenstephanie
sannewaumans
gisele.marien
marjanvervoort
gittejacobs
anke.vc
lieve66
lepitaaaa
els.vangenechten
marieke_geysels
laurenmichiels_
ttvanlitsenburg
liesbetdepreter
laurarosche
katrienvroom
sofievolders_
kimvannijlen
kim_dc
iffets0803
liesjevd
sandhyakeee
elke_1992
verwimplotte
evamichiels
jentevangemert
marie_lowie
laurienverboven
kaatvhr
janssens_ll
annemiedepaz
jo.belmans
marieke_cools
__valerie_b
kimmekem
karenreynders
anneliesswinnen6
kimdelimoen
floormelis
_stephani___
leenkarel
lorecrwls
tinesnels
femke_d
jelleliekens
karin_clonen
lies_heylen
miekeboes
diedeverlinden
fitisroose
nathalieolislaegers
fienhoudmeyers
ernawillems
dewolflena
katrien.vanderveken
kapsalon.huis_henri
roxannekeymeulen
leenstruyf
ine.alex
tinewitvrouwen
amberneefs
geukens.claudia
bovenkaren
ingederoy
carinbertels
asset2016
marijnmm
catogeskens
elsvanhemelen
jornschroyen
karlienhuysmans
isa_delanote
d13ls
ethi711
rup_v_l
woutwoussen
liesbet.v.d
klaartjevanuytven
anneleenvr
ericalathouwers
katjantretil
isabelle.vanvlerken
ellenvanloy
els.lenaerts
charlottequets
elizesauvillers
wolkenwater
sprengersannick
kaara_h
liesbett
louisejnssns
liesel_geerts
tinne_driesen
elisevanaelst
shaunivaneester
paulienhelsen
sara_simply_me
katriienj
gerlindehoskens
vangestelkristien
dymphnadc
biededoncker
ellen_triangle
chrisje_bertels
sokilevi
lore.cumps
joke_aerts
annickswaans
nickyvaneynde
devoslisaaa
valerie.delien
faesmaria
tante_karen
kimmmaerts
yaminamer
sara.ker
tinnedams
valerieburgers
n_e_l_e_4
charlottewilmss
hannecoeimans
lieze_vo
jolleeee
loen_pauwels
martdehenau
nxk31
liesbeth.vandenbulck
lilianedaemsbe
leenheyl
floreforella
tinnevoet
l_dierckx
yenthemarien
dejassie
ruthcools
kim.celen
hanne.verdonck
karenvanspaendonk
lien_cools
a.fineline.tattoo
carolienvangheel
jirka.govers
sabinedesauter
isa_noyens
tinnevankerckhoven
zoedebie
stefaniesannen
sanneverryckt
loreverryckt
katrienproost
marissesmets
katrien_de_roover
marievandingenen
kristienverwimp
vleugelsjulie
marciapareijn
marcia.music.artist
diyacha
katriencaers2
t.d.l.3
missvleugels
jill.vandael
noreanthoni
xhoenballiu
ave_rbbrchts
dehouwers
amygeuens
sofiehermans
elinedams
jasmien.boeckmans
renildeindeherberge
martinelievens
christyvanwijnendaele
charlottevanherk
sschepers
shootah014
neleverboven
jolienverboven
christelmacke
villakoekelat
karlienlaenen
karen_bergh
dietborghs
bente_smits
anneke_bosmans
yentlberghmans
annickgeerits
lotteaerts_
liesverbeemen
marieke.meeus
anneloredams
inekevana
martevanloy
v_to_the_alerie
matisseraeymaekers
aperture_8.6_fotografie
irisdaems
ansversmissen
sigridvnbrl
sw_valerie
shaunivosters
yentltsyen
babsvanhulle
katrienlambrechts
hanneke1812
mirte_breels
verwimp_s
sofiesnn_
ellabuschgens
elkevc1
mampaeyjulie
elly_moors
nina_lamain
jas.mmmien
astriddegroof
vanity_d
tessagssns
sannegeldof
nancy_geert_bel
fevelien
griet_things
nienkebienstman
boermans.cita
valerie.bakelants
yentl.lievens
geenslins
arjan.cruysberghs
kathleenvanbosch
carolajna87
elienpeys
jokovanloco
isesterckx
emmabauwens
marliesscheveneels
sophiwim
jannecamps
katrijn.peeters.37
silkenevelsteen
nathalieholistic
karolien_verrezen
cheelung
teunhaesen
tine_verwimp
falkecels
andekort
joyce.gevers
marthe.sl
hanneloredrijvers
nele_molenberghs
lien_goor
liesje.soontjens
yourvibe_yogastudio
ankegoris
stephaniemaes1
moodmakeupstudio
inevaneerdewegh
living_at_29a
sarapelgrims
eveline_van_hemelrijk
yentljns
emiliedierckx
tie_vie
liesbethhaeseldonckx
fannyboeykens
tnptrs
debbiewillems
vdvrenee
ann__claes
lodemaertens
marthejnsn
elsgoots
jolienwuyts
leengroenen
tuurvandoninck
leysenliesbeth
jolienwarpy
floorgils
elke.vanherck
lien_verachtert
tinnebraspenning
biekedekeyzer
vekemansannelies
alberthaesen1
joyceepeetersss
ellykennis
tinneverellen
cindy__thijs
nathalietsyen
tina_stiens
kimdewachter
zapmama_7
kaatverfaillie
cathydewinter
paulienverachtert
marie_camille_gyselinck
stienvermeulen
vaneyndehannah
marleeen91
sofiecallaey
miekwynen
weverberghvanessa
voetsmartine
louise_gyselinck
ellenbastiaens
chantalbertels
xeniaverhulst
loekaleysen
metteengelen
sofieholvoet
groenenans
geudensliesbeth
noortjeproost
aureliepauwels
loregodenir
jill.gebo
momwithusher
julievanderstappen
wendy_laenen
floorjanssenss
lori_du_bois
tessaembrechts
cvanolmen
evaverschuurfotografie
vierkuikensindehaven
hannekerckhofss_
kellybraes
joke_bruers
mieketjeuh
melissa.meeus
rixt.bruining
car0k3
mombers_bert
huygheels
karlijnverachtert
jillcuyvers
lieve_dt
lieke_taarten
pauliennuyts
Hannah Van Eynde
Stien Vrmln
Marie-Camille 
Ariane Thomas
Tina Stiens
Tinne Verellen
Joyce Peeters
Bieke De Keyzer
Tinne Braspenning
Liesbeth Leysen
Marthe Gilis
Tuur Vandoninck
Toon Van Herck
Els Goots
Peggy Vercaigne
Bente Smits
Emilie Dierckx
Eveline Van Hemelrijk
yentljns
Ans Versmissen
Tessa Goossens
hanneloredrijvers
stephaniemaes1
karlienlaenen
joyce.gevers
levantaci
brigittecuyvers
elienpeys
valerie.bakelants
nienkebienstman
Sanne Geldof
kimmmaerts
karodauwen
Tine Verwimp
Karolien_verrezen
Sophie De Bruyne
yentl.lievens
fevelien
vanity_d
jas.mmmien
Tijl Geyssens
sofiesnn_
aperture_8.6_fotografie
v_to_the_alerie
jytteooms
Yentl Berghmans
dietborghs
hannecoeimans
shootah014
sschepers
christyvanwijnendaele
Amy Geuens
elinedams
jill.vandael
katriencaers2
missvleugels
marievandingenen
zoedebie
lien_cools
tinnevoet
yenthemarien
l_dierckx
floreforella
elkeboerboom
linebylemans
jolleeee
charlottewilmss
n_e_l_e_4
valerieburgers
tinnedams
joke_aerts
sokilevi
katriienj
shaunivaneester
kimdelimoen
liesel_geerts
karin_clonen
evasteenacker
els.lenaerts
charlottequets
ericalathouwers
anneleenvr
woutwoussen
rup_v_l
catogeskens
marijnmm
bovenkaren
Ingederoy
geukens.claudia
ine.alex
femke_d
hilde.willems
fienhoudmeyers
leenkarel
vervoort.annelies
tinesnels
___valerie_b
janssenjoke
kaatvhr
figurelgeel_
sandhyakeee
gittejacobs
kim_dc
els.vangenechten
laurenmichiels_
liesbetdepreter
sophie.boeckx
charlottelievens
sannewaumans
gisele.marien
thoelenstephanie
evelien_ae
linkavd
laurebelmans
kerckhofslaura
___trienka11___
marienhanne
biekepeeterss
kristelmeeus
hanne.starckx
ansjeverrezen
brittluyckx
karlijnthys
loeswynants
marijke_verbeeck
cathovangompel
ambertaels
valerie_gns
shanavaneysendeyk
nelebeerten
tessalauw
stefaniemermans
heidigeerts
bopenasse
celien_dercon
nele_van_de_reyd
samooms
joliengoormans
vdb.jes
katriengeenen
tinadebeckerwimtina
toongerinckx
ruth_vrhrt
vickyverstrepen
verachtertcharlotte
verwimps
juliewouter
bianca__wouters
xlorebeckers
lotjevandoninck
marthevanautryve
lisehendrickx
lisemut
lisa.schaevers
nickybuyens
lisawuyts
katrien.achten
nelevanwesemael
katriendier
laurienoyen
tinne_vn
brittbelis
fleur_verstappen
eline_bgrts
gieseliekens
jannedams
tin.daems
vincentmelis
lisa.vrdnck
lottevos1
lieslcools
martevermeulen
annelieslembreghts
hannelieten
laura.engelen
lien_daems
gudrunvanherck
kirstenvanspringel
shanaschvnls
marlies.vanherck
siel_____
elinenevelsteen
lietenfemke
romi_aerts
margotdierckx
elienceelen
jo_beerten
woutersyoni
vwhanne
jannethys
tessaerpels
nellepeeters
celienvanbaelen
kaatvandenbergh
vancauterm
charlottevandervelde_
claudiavandael
sietsegebruers
bogaertskaren
femkesnoeck
julieborghs
jennaaa_eyckmans
debbie_haesen
emilie_wuyts
loreschodts
extravierge
laura_vndrvoort
laura.bogaerts
valeriasereda_
kaayaeyckmans
lindseyvleugels
floormathieu
jannestroo
jessicapieroni
holemanskiki
an.teunkens
sielkedaems
bertillevanroosbroeck
sannebee
janavoets
heynslisa
janssens_jana
ann.dho2
jetsevanbouwel
renee.vandecruys
annevandecraen
tinnelauvrys
ingepeeters
lieselotreynders
postfrommars
k.lrmns
lynnfransen1
seesje
brittclaesens
karenkuppens
elisabellefroid
___habiba_christina_____
elisadenys
carolineoeyen
dorien_brosens
roosje.goos
jillpreudhomme
rietbockx
valeriepaulussen
femvandebroek
silkeclaessen
ellenverbeeck
griet_henrickx_
evaverbraecken
stickensamber
tjulessss
katrienruts
truisyen
anke_w_
jantejonkers
vandonincka
katrinetje
ellis.goolaerts
peetersalice
geukensa
grietsmeyers
hannevansoom
kellysihna
mar_hnns
elsvanvossole
celinealenteyns
annelorereynders
michielsjanne
charlottegeens
ninavangerven1
v_h_jelle
vandesandemarthe
veerlejacobs
bieterweduwe
evyscherrens
lisanietvelt
marianneverachtert 
chanahiserman
lisa_lauuu
anneliesgeerts
antonialandrieu
evelinejanssenswillen
undercovereend
winifloreal
lienwellens
fienvanrompay
kathleenmaes
kelly_rekm
cluadiademaeght
zitavennekens
kaat.dillen
lorekeppers
charlottedetemmerman
karoliendgsn
biesmeyers
saarwgmns
charlottevannuffelen
emilyvhasselt
_anneleenc_
basilandcoffee
anneliespauwels
simkestaes
loremols
loekaleysen
metteengelen
sofieholvoet
cvanolmen
mieketjeuh
car0k3
hannekerckhofss_
momwithusher
aureliepauwels
geudensliesbeth
kellybraes
melissa.meeus
lieke_taarten
lieve_dt
noortjeproost
chantalbertels`
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onStartPicker = (e) => {
    e.preventDefault()

    let names = []

    const onlyUnique = (value, index, self) => {
      return self.indexOf(value) === index;
    }

    if (this.state.facebook.length > 0) {
      this.state.facebook.split('\n').forEach(i => {
        names.push({ value: i, type: 'fb'})
      })
    }
    if (this.state.instagram.length > 0) {
      this.state.instagram.split('\n').forEach(i => {
        names.push({ value: i, type: 'ig'})
      })
    }

    names = names
      .map((a) => ({sort: Math.random(), value: a}))
      .sort((a, b) => a.sort - b.sort)
      .map((a) => a.value)

    this.props.onStart(names)
  }

  render() {
    const { loading, instagram, facebook } = this.state

    return (
      <AppContext.Consumer>
        {context => {
          this.context = context

          return (
            <Widget title={<><i className="fas fa-trophy"></i> Picker</>} loading={loading}>
              <form>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group row">
                      <label htmlFor="facebookNames" className="col-sm-4 col-form-label">
                        <i className="fab fa-facebook-square"></i> Facebook namen
                        <small className="form-text text-muted">1 naam per lijn</small>
                      </label>
                      <div className="col-sm-8">
                        <textarea
                          className="form-control"
                          id="facebookNames"
                          name="facebook"
                          placeholder="Facebook namen"
                          rows="5"
                          value={facebook}
                          onChange={this.onChange} />
                        <div className="row">
                          <div className="col-4">
                          </div>
                          <div className="col-4">
                          </div>
                          <div className="col-4 text-right">
                            <button className="btn btn-danger">
                              <i className="fas fa-times"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group row">
                      <label htmlFor="instagramNames" className="col-sm-4 col-form-label">
                        <i className="fab fa-instagram"></i> Instagram namen
                        <small className="form-text text-muted">1 naam per lijn</small>
                      </label>
                      <div className="col-sm-8">
                        <textarea
                          className="form-control"
                          id="instagramNames"
                          name="instagram"
                          placeholder="Instagram namen"
                          rows="5"
                          value={instagram}
                          onChange={this.onChange} />
                        <div className="row">
                          <div className="col-4">
                          </div>
                          <div className="col-4">
                          </div>
                          <div className="col-4 text-right">
                            <button className="btn btn-danger">
                              <i className="fas fa-times"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group row">
                      <div className="col-sm-8 offset-sm-4">
                        <button className="btn btn-primary btn-block" onClick={this.onStartPicker}>
                          Volgende <i className="fas fa-chevron-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </Widget>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

export default CampaignPickerConfigurationWidget
